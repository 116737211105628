import { useEffect } from "react";
import { Suspense, lazy } from "react";
import AOS from "aos";
import { Route, Routes, useLocation } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { UnreadMessagesProvider } from "./context/UnreadMessagesProvider";
import { EarlyAccessBanner } from "./components/homepage/EarlyAccessBanner";
import { Header } from "./components/layout/Header";
import { Footer } from "./components/layout/Footer";
import { PrivateRoute } from "./components/routing/PrivateRoute";
import { StripeProvider } from "./components/payments/StripeProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Spinner } from "./components/ui/Spinner";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import "aos/dist/aos.css";
import "./App.css";
import { LanguageProvider } from "./context/LanguageContext";
import Pricing from "./components/pages/Pricing";
import { PaymentSuccess } from "./components/payments/PaymentSuccess";

ReactGA.initialize("G-08ZBPL1X65"); // Google Analytics

// Lazy load components
const HomePage = lazy(() => import("./components/homepage"));
const AuthForm = lazy(() => import("./components/auth/AuthForm"));
const MyStory = lazy(() => import("./components/pages/MyStory"));
const FAQPage = lazy(() => import("./components/pages/FAQPage"));
const ContactUs = lazy(() => import("./components/pages/ContactUs"));
const FeedbackForm = lazy(() => import("./components/pages/FeedbackForm"));
const PrivacyPolicy = lazy(() => import("./components/pages/PrivacyPolicy"));
const PrivacyPolicyDE = lazy(
  () => import("./components/pages/PrivacyPolicyDE")
);
const NotFound = lazy(() => import("./components/pages/NotFound"));
const Community = lazy(() => import("./components/community/Community"));
const FavoritesList = lazy(() => import("./components/favorites"));
const Settings = lazy(() => import("./components/settings"));
const PasswordReset = lazy(() => import("./components/auth/PasswordReset"));
const VerifyEmailPage = lazy(() => import("./components/auth/VerifyEmailPage"));
const InterestsList = lazy(
  () => import("./components/user-profile/interests/InterestsList")
);
const UserStory = lazy(
  () => import("./components/user-profile/user-story/UserStory")
);
const PasswordResetRequest = lazy(
  () => import("./components/auth/PasswordResetRequest")
);
const PaymentInfoPage = lazy(
  () => import("./components/payments/PaymentInfoPage")
);
const UserProfile = lazy(
  () => import("./components/user-profile/UserProfileForm")
);
const UserPublicProfile = lazy(
  () => import("./components/user-profile/UserPublicProfile")
);
const ConversationsPage = lazy(
  () => import("./components/chat-system/ConversationsPage")
);

const HowItWorks = lazy(() => import("./components/how-it-works/HowItWorks"));

const queryClient = new QueryClient();

export const App: React.FC = () => {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const isHomePage =
    location.pathname === "/" || location.pathname === "/index.html";

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <UnreadMessagesProvider>
            <StripeProvider>
              <AuthProvider>
                {isHomePage && <EarlyAccessBanner />} <Header />
                <Suspense fallback={<Spinner />}>
                  <div>
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/auth" element={<AuthForm />} />
                      <Route
                        path="/reset-password-request"
                        element={<PasswordResetRequest />}
                      />
                      <Route
                        path="/reset-password"
                        element={<PasswordReset />}
                      />
                      <Route
                        path="/verify-email"
                        element={<VerifyEmailPage />}
                      />
                      <Route path="/my-story" element={<MyStory />} />
                      <Route path="/how-it-works" element={<HowItWorks />} />
                      <Route path="/pricing" element={<Pricing />} />
                      <Route
                        path="/privacy-policy"
                        element={
                          i18n.language === "de" ? (
                            <PrivacyPolicyDE />
                          ) : (
                            <PrivacyPolicy />
                          )
                        }
                      />
                      <Route path="/faq" element={<FAQPage />} />
                      <Route path="/contact-us" element={<ContactUs />} />
                      <Route path="/feedback" element={<FeedbackForm />} />
                      <Route path="/community" element={<Community />} />
                      <Route
                        path="/profile"
                        element={<PrivateRoute component={UserProfile} />}
                      />
                      <Route
                        path="/profile-view/:userId"
                        element={<PrivateRoute component={UserPublicProfile} />}
                      />

                      <Route
                        path="/payment-success"
                        element={<PrivateRoute component={PaymentSuccess} />}
                      />

                      <Route
                        path="/messages"
                        element={<PrivateRoute component={ConversationsPage} />}
                      />

                      <Route
                        path="/settings/*"
                        element={<PrivateRoute component={Settings} />}
                      />

                      <Route
                        path="/favorites"
                        element={<PrivateRoute component={FavoritesList} />}
                      />

                      <Route
                        path="/interests-list"
                        element={<PrivateRoute component={InterestsList} />}
                      />

                      <Route
                        path="/user-story"
                        element={<PrivateRoute component={UserStory} />}
                      />

                      <Route
                        path="/payment-info"
                        element={<PrivateRoute component={PaymentInfoPage} />}
                      />

                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </div>
                </Suspense>
                <Footer />
              </AuthProvider>
            </StripeProvider>
          </UnreadMessagesProvider>
        </LanguageProvider>
      </QueryClientProvider>
    </div>
  );
};
