import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase/firebase-configuration";
import { User } from "../types";
import {
  deleteUser,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updateProfile,
} from "firebase/auth";

export const fetchUserByIdService = async (
  userId: string
): Promise<User | null> => {
  try {
    const userDoc = await getDoc(doc(db, "users", userId));
    if (userDoc.exists()) {
      return { id: userDoc.id, ...userDoc.data() };
    }
    return null;
  } catch (error) {
    console.error("Error fetching user:", error);
    return null;
  }
};

export const fetchUsersByIdsService = async (userIds: string[]) => {
  try {
    if (!userIds.length) return [];
    const usersQuery = query(
      collection(db, "users"),
      where("__name__", "in", userIds)
    );
    const querySnapshot = await getDocs(usersQuery);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error fetching users by IDs:", error);
    throw error;
  }
};

export const updateUserService = async (userId: string, userData: any) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, userData);
  } catch (error) {
    console.error("Error updating user:", error);
  }
};

export const fetchAllUsersCount = async () => {
  try {
    const usersSnapshot = await getDocs(collection(db, "users"));
    return usersSnapshot.size;
  } catch (error) {
    console.error("Error fetching all users count:", error);
    throw new Error("Failed to fetch users count");
  }
};

export const updateUserDataService = async (uid: string, data: any) => {
  try {
    if (data.name) {
      await updateProfile(auth.currentUser!, { displayName: data.name });
    }

    const userDocRef = doc(db, "users", uid);
    const userDoc = await getDoc(userDocRef);
    const currentData = userDoc.exists() ? userDoc.data() : {};

    // Merge current Firestore data with new form data
    const birthdayDate = data.birthday ? new Date(data.birthday) : null;
    const updatedUserData = {
      ...currentData,
      ...data,
      birthday: birthdayDate,
    };

    await updateDoc(userDocRef, updatedUserData);
    return updatedUserData;
  } catch (error) {
    console.error("Error updating user data:", error);
  }
};

export const deleteUserAccountService = async (password: string) => {
  if (!auth.currentUser) {
    throw new Error("No authenticated user found.");
  }

  try {
    // Step 1: Re-authenticate user
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email!,
      password
    );
    await reauthenticateWithCredential(auth.currentUser, credential);

    // Step 2: Delete user document from Firestore
    const userDocRef = doc(db, "users", auth.currentUser.uid);
    await deleteDoc(userDocRef);

    // Step 3: Delete user from Firebase Auth
    await deleteUser(auth.currentUser);
  } catch (error) {
    console.error("Error deleting account:", error);
    throw new Error("Failed to delete user account. Please try again.");
  }
};

export const fetchBlockedUsersService = async () => {
  if (!auth.currentUser) throw new Error("No authenticated user found.");

  try {
    const userDocRef = doc(db, "users", auth.currentUser.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) throw new Error("User document does not exist.");

    const blockedUserIds = userDoc.data()?.blockedUsers || [];
    if (blockedUserIds.length === 0) return [];

    const blockedUsersData = await Promise.all(
      blockedUserIds.map(async (blockedUserId: string) => {
        const blockedUserDoc = await getDoc(doc(db, "users", blockedUserId));
        return { id: blockedUserId, ...blockedUserDoc.data() };
      })
    );

    return blockedUsersData;
  } catch (error) {
    console.error("Error fetching blocked users:", error);
    throw error;
  }
};

export const unblockUserService = async (userId: string) => {
  if (!auth.currentUser) throw new Error("No authenticated user found.");

  try {
    const userDocRef = doc(db, "users", auth.currentUser.uid);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) throw new Error("User document does not exist.");

    const userData = userDoc.data();
    const { blockedUsers } = userData || {};

    if (!blockedUsers || !Array.isArray(blockedUsers)) {
      throw new Error("No blocked users found.");
    }

    const updatedBlockedUserIds = blockedUsers.filter(
      (blockedUserId: string) => blockedUserId !== userId
    );

    await updateDoc(userDocRef, { blockedUsers: updatedBlockedUserIds });
  } catch (error) {
    console.error("Error unblocking user:", error);
    throw error;
  }
};

export const toggleFavoriteService = async (
  userId: string,
  isFavorite: boolean
) => {
  try {
    const userRef = doc(db, "users", auth.currentUser?.uid as string);
    const userSnap = await getDoc(userRef);
    const currentFavorites = userSnap.data()?.favorites || [];

    if (isFavorite) {
      await updateDoc(userRef, {
        favorites: currentFavorites.filter((id: string) => id !== userId),
      });
    } else {
      await updateDoc(userRef, {
        favorites: [...currentFavorites, userId],
      });
    }
  } catch (error) {
    console.error("Error toggling favorite:", error);
    throw error;
  }
};

export const updateUserInterestsService = async (
  userId: any,
  selectedInterests: any[]
) => {
  try {
    const docRef = doc(db, "users", userId);
    await setDoc(docRef, { interests: selectedInterests }, { merge: true });
  } catch (error) {
    console.error("Error updating user interests:", error);
    throw error;
  }
};
