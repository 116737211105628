import enCommon from "./en/common.json";
import enHome from "./en/home.json";
import enChat from "./en/chat.json";
import enFavorites from "./en/favorites.json";
import enUserProfile from "./en/userProfile.json";
import enPricing from "./en/pricing.json";
import enMyStory from "./en/myStory.json";
import enHeaderFooter from "./en/headerFooter.json";
import enAuth from "./en/auth.json";
import enFaq from "./en/faq.json";
import enFeedbackForm from "./en/feedbackForm.json";
import enContactUs from "./en/contactUs.json";
import enPayment from "./en/payment.json";
import enCommunity from "./en/community.json";
import enSettings from "./en/settings.json";
import enOtherCategories from "./en/otherCategories.json";
import enPersonalPreferences from "./en/categories/personalPreferences.json";
import enInterests from "./en/categories/interests.json";
import enFitness from "./en/categories/fitness.json";
import enRelationships from "./en/categories/relationships.json";
import enJobFields from "./en/categories/jobFields.json";
import enLanguages from "./en/categories/languages.json";
import enPersonalityType from "./en/categories/personalityType.json";
import enHowItWorks from "./en/HowItWorks.json";

import deCommon from "./de/common.json";
import deHome from "./de/home.json";
import deChat from "./de/chat.json";
import deFavorites from "./de/favorites.json";
import deUserProfile from "./de/userProfile.json";
import dePricing from "./de/pricing.json";
import deMyStory from "./de/myStory.json";
import deHeaderFooter from "./de/headerFooter.json";
import deAuth from "./de/auth.json";
import deFaq from "./de/faq.json";
import deFeedbackForm from "./de/feedbackForm.json";
import deContactUs from "./de/contactUs.json";
import dePayment from "./de/payment.json";
import deCommunity from "./de/community.json";
import deSettings from "./de/settings.json";
import deOtherCategories from "./de/otherCategories.json";
import dePersonalPreferences from "./de/categories/personalPreferences.json";
import deInterests from "./de/categories/interests.json";
import deFitness from "./de/categories/fitness.json";
import deRelationships from "./de/categories/relationships.json";
import deJobFields from "./de/categories/jobFields.json";
import deLanguages from "./de/categories/languages.json";
import dePersonalityType from "./de/categories/personalityType.json";
import deHowItWorks from "./de/HowItWorks.json";

export const translations = {
  en: {
    common: enCommon,
    home: enHome,
    chat: enChat,
    favorites: enFavorites,
    userProfile: enUserProfile,
    pricing: enPricing,
    myStory: enMyStory,
    headerFooter: enHeaderFooter,
    auth: enAuth,
    interests: enInterests,
    faq: enFaq,
    feedbackForm: enFeedbackForm,
    contactUs: enContactUs,
    payment: enPayment,
    community: enCommunity,
    settings: enSettings,
    categories: enOtherCategories,
    personalPreferences: enPersonalPreferences,
    fitness: enFitness,
    relationships: enRelationships,
    jobFields: enJobFields,
    languages: enLanguages,
    personalityType: enPersonalityType,
    howItWorks: enHowItWorks,
  },
  de: {
    common: deCommon,
    home: deHome,
    chat: deChat,
    favorites: deFavorites,
    userProfile: deUserProfile,
    pricing: dePricing,
    myStory: deMyStory,
    headerFooter: deHeaderFooter,
    auth: deAuth,
    interests: deInterests,
    faq: deFaq,
    feedbackForm: deFeedbackForm,
    contactUs: deContactUs,
    payment: dePayment,
    community: deCommunity,
    settings: deSettings,
    categories: deOtherCategories,
    personalPreferences: dePersonalPreferences,
    fitness: deFitness,
    relationships: deRelationships,
    jobFields: deJobFields,
    languages: deLanguages,
    personalityType: dePersonalityType,
    howItWorks: deHowItWorks,
  },
};
