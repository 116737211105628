import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fetchAllUsersCount } from "../../services/userService";

export const EarlyAccessBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const { t: translate } = useTranslation("common");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersCount = await fetchAllUsersCount();
        setShowBanner(usersCount < 500);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!showBanner) return null;

  return (
    <div className="bg-[#ce8f7e] text-white p-4 text-center shadow-md">
      <p className="text-md sm:text-lg font-semibold">
        {translate("early_access_banner.message")}
      </p>
    </div>
  );
};
