import { createContext, useContext } from "react";
import { User } from "../types";

interface AuthContextType {
  user: User | null;
  signedInUserData: User | null;
  loading: boolean;
  updateUserImage: (newImage: string) => void;
  refreshUserData: () => void;
  isVerified: boolean;
  setIsVerified: any;
  onboardingStep: any;
  setOnboardingStep: any;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  loading: true,
  updateUserImage: () => {},
  signedInUserData: null,
  refreshUserData: () => {},
  isVerified: false,
  setIsVerified: null,
  onboardingStep: null,
  setOnboardingStep: null,
});

export const useAuth = () => useContext(AuthContext);
