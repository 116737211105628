import React from "react";
import { Link, useNavigate } from "react-router-dom";
import celebration from "../../../src/assets/celebration.png";
import { useTranslation } from "react-i18next";

export const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { t: translate } = useTranslation("payment");

  const handleGoToCommunity = () => {
    navigate("/community");
  };

  return (
    <div className="min-h-screen py-16 px-6 flex items-center justify-center mb-40">
      <div className="text-center max-w-3xl bg-white shadow-lg rounded-lg p-8">
        <div className="mb-8">
          <img
            src={celebration}
            alt={translate("paymentSuccess.thankYouMessage")}
            className="mx-auto w-1/2 h-auto"
          />
        </div>

        <section aria-labelledby="payment-success">
          <h1
            id="payment-success"
            className="mb-6 text-2xl md:text-4xl font-bold text-gray-800"
          >
            <span className="block">
              {translate("paymentSuccess.thankYouMessage")}
            </span>
          </h1>
        </section>

        <p className="text-lg text-gray-600 mb-10 text-center">
          {translate("paymentSuccess.communityMessage")}
        </p>

        <div className="text-center mb-12">
          <button
            onClick={handleGoToCommunity}
            className="bg-accent hover:bg-buttonHover text-white rounded-md px-8 py-3 transition duration-300 text-md md:text-lg tracking-wide shadow-lg hover:shadow-xl"
          >
            {translate("paymentSuccess.joinCommunityButton")}
          </button>
        </div>

        <p className="text-gray-500">
          {translate("paymentSuccess.haveQuestions")}
          <Link to="/contact-us" className="text-primary underline">
            {translate("paymentSuccess.contactSupport")}
          </Link>
        </p>
      </div>
    </div>
  );
};
