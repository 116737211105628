import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase/firebase-configuration";
import { Spinner } from "../ui/Spinner";
import { useTranslation } from "react-i18next";
import { fetchAllUsersCount } from "../../services/userService";
import { useAuth } from "../../context/AuthContext";

const Pricing: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalUsersCount, setTotalUsersCount] = useState<any>();
  const { signedInUserData } = useAuth();
  const { t: translate } = useTranslation("pricing");

  useEffect(() => {
    let isMounted = true; // Ensures cleanup on unmount (avoid memory leak)

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const usersCount = await fetchAllUsersCount();
        if (!isMounted) return;
        setTotalUsersCount(usersCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (isMounted) setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const getLinkDestination = (isStandard: boolean) => {
    if (!auth.currentUser) return "/auth";

    if (signedInUserData?.hasPaid || signedInUserData?.hasFreeAccess)
      return "/community";

    // Early Access Logic, free access for first 500 users
    if (!isStandard && totalUsersCount < 500) return "/community";

    // Not paid and no free access, redirect to payment page
    return "/payment-info";
  };

  if (isLoading) return <Spinner />;

  return (
    <section className="min-h-screen py-20">
      <div className="max-w-4xl mx-auto text-center">
        <section aria-labelledby="join-us">
          <h1 id="join-us" className="mx-10 mb-4 text-2xl md:text-4xl">
            <span className="colored-heading">
              {translate("pricing.joinUsToday")}
            </span>
          </h1>
        </section>

        <p className="text-lg text-gray-600 mb-2 mx-8">
          {translate("pricing.joinUsDescription1")}
        </p>

        <p className="text-lg text-gray-600 mb-10 mx-8">
          {translate("pricing.joinUsDescription2")}
        </p>

        <div className="grid grid-cols-1 mx-8 md:grid-cols-2 gap-8">
          {/* Free Plan */}
          <div className="border border-gray-200 rounded-lg shadow-sm p-8">
            <h3 className="text-2xl font-bold text-gray-800 mb-2">
              {translate("pricing.earlyAccess")}
            </h3>
            <p className="text-gray-600 mb-6">
              {" "}
              {translate("pricing.earlyAccessDescription")}
            </p>
            <ul className="text-gray-700 text-sm mb-6">
              <li className="mb-2">
                ✓ {translate("pricing.features.communityAccess")}
              </li>
              <li className="mb-2">
                ✓ {translate("pricing.features.profileCustomization")}
              </li>
              <li>✓ {translate("pricing.features.unlimitedMessaging")}</li>
            </ul>
            <Link
              to={getLinkDestination(false)}
              className="w-full block bg-accent hover:bg-buttonHover text-white rounded-md px-8 py-2 transition duration-300 text-md md:text-lg tracking-wider shadow"
            >
              {translate("pricing.getStarted")}
            </Link>
          </div>

          {/* Standard Plan */}
          <div className="border border-gray-200 rounded-lg shadow-sm p-8">
            <h3 className="text-2xl font-bold text-gray-800 mb-2">
              {translate("pricing.standardAccess")}
            </h3>
            <p className="text-gray-600 mb-6">
              {translate("pricing.standardAccessDescription")}
            </p>
            <ul className="text-gray-700 text-sm mb-6">
              <li className="mb-2">
                ✓ {translate("pricing.features.communityAccess")}
              </li>
              <li className="mb-2">
                ✓ {translate("pricing.features.profileCustomization")}
              </li>
              <li>✓ {translate("pricing.features.unlimitedMessaging")}</li>
            </ul>
            <Link
              to={getLinkDestination(true)}
              className="w-full block bg-accent hover:bg-buttonHover text-white rounded-md  px-8 py-2 transition duration-300 text-md md:text-lg tracking-wider shadow"
            >
              {translate("pricing.joinNow")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
