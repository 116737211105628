import React, { useState, ReactNode, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { AuthContext } from "./AuthContext";
import { auth, db } from "../firebase/firebase-configuration";
import { Spinner } from "../components/ui/Spinner";
import { doc, getDoc } from "firebase/firestore";
import { fetchUserByIdService } from "../services/userService";
import { User } from "../types";

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [signedInUserData, setSignedInUserData] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const [onboardingStep, setOnboardingStepState] = useState<string | null>(() =>
    localStorage.getItem("onboardingStep")
  );

  const setOnboardingStep = (step: string | null) => {
    setOnboardingStepState(step);
    if (step) {
      localStorage.setItem("onboardingStep", step);
    } else {
      localStorage.removeItem("onboardingStep");
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser: any) => {
      setUser(currentUser);
      setLoading(false);
      setIsVerified(currentUser ? currentUser.emailVerified : false);
      if (currentUser) {
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        if (userDoc.exists()) {
          setSignedInUserData(userDoc.data() as User);
        }
      } else {
        setSignedInUserData(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const refreshUserData = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const userData = await fetchUserByIdService(currentUser.uid);
      setSignedInUserData(userData as User);
    }
  };

  const updateUserImage = (newImage: string) => {
    if (signedInUserData) {
      setSignedInUserData({ ...signedInUserData, photoURL: newImage });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        signedInUserData,
        loading,
        updateUserImage,
        refreshUserData,
        isVerified,
        setIsVerified,
        onboardingStep, // Provide the state
        setOnboardingStep, // Provide the updater function
      }}
    >
      {loading ? <Spinner /> : children}
    </AuthContext.Provider>
  );
};
