import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";

export const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const { selectedLanguage, setSelectedLanguage } = useLanguage();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const currentLanguage = i18n.language.toUpperCase();
    if (currentLanguage !== selectedLanguage) {
      setSelectedLanguage(currentLanguage);
    }
  }, [i18n.language, selectedLanguage, setSelectedLanguage]);

  const handleLanguageChange = (lng: string) => {
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng.toLowerCase());
    setIsDropdownOpen(false);
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        className="inline-flex justify-between items-center w-auto px-2 py-1 border border-gray-300 rounded-md bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
        aria-expanded={isDropdownOpen}
      >
        {selectedLanguage}
        <svg
          className="ml-1 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div
          className="absolute right-0 mt-1 w-auto bg-white rounded-md shadow-lg border border-gray-200 z-50"
          style={{ minWidth: "fit-content" }}
        >
          <button
            onClick={() => handleLanguageChange("EN")}
            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            EN
          </button>
          <button
            onClick={() => handleLanguageChange("DE")}
            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            DE
          </button>
        </div>
      )}
    </div>
  );
};
