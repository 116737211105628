import React, { createContext, useState, useEffect, useContext } from "react";
import { auth, db } from "../firebase/firebase-configuration";
import { collection, onSnapshot, query } from "firebase/firestore";

const UnreadMessagesContext = createContext<any>(null);

export const UnreadMessagesProvider: React.FC<any> = ({ children }) => {
  const [unreadMessages, setUnreadMessages] = useState(false);
  const [currentUser, setCurrentUser] = useState<string | null>(null);

  // Listen for authentication state changes to update the current user
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user?.uid || null);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    // Listen for real-time updates to the conversations collection
    const q = query(collection(db, "conversations"));
    const unsubscribeConversations = onSnapshot(q, (querySnapshot) => {
      let hasUnread = false;

      querySnapshot.docs.forEach((conversationDoc) => {
        const conversationId = conversationDoc.id;
        const messagesRef = collection(
          db,
          `conversations/${conversationId}/messages`
        );

        const unsubscribeMessages = onSnapshot(
          messagesRef,
          (messagesSnapshot) => {
            messagesSnapshot.docs.forEach((messageDoc) => {
              const messageData = messageDoc.data();

              if (
                messageData.recipientId === currentUser && // Current user is the recipient
                messageData.senderId !== currentUser && // Current user is not the sender
                (!messageData.readBy ||
                  !messageData.readBy.includes(currentUser)) // Message hasn't been read by current user
              ) {
                hasUnread = true;
              }
            });

            // Only update state if there's a change to avoid unnecessary re-renders
            if (unreadMessages !== hasUnread) {
              setUnreadMessages(hasUnread);
            }
          }
        );

        return () => unsubscribeMessages();
      });
    });

    return () => unsubscribeConversations();
  }, [currentUser]);

  // New function to clear unread messages when the user clicks on the chat
  const clearUnreadMessages = () => {
    setUnreadMessages(false);
  };

  return (
    <UnreadMessagesContext.Provider
      value={{ unreadMessages, clearUnreadMessages }}
    >
      {children}
    </UnreadMessagesContext.Provider>
  );
};

export const useUnreadMessages = () => useContext(UnreadMessagesContext);
