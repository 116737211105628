import React from "react";
import { ReactNode } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

if (!stripePublicKey) {
  throw new Error(
    "Stripe public key is not defined. Please set REACT_APP_STRIPE_PUBLIC_KEY."
  );
}

const stripePromise = loadStripe(stripePublicKey);

export const StripeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <Elements stripe={stripePromise}>{children}</Elements>;
};
