import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "./LanguageSwitcher";

export const Footer = () => {
  const { t: translate } = useTranslation("headerFooter");

  return (
    <footer className="p-6 flex flex-col bg-primary text-white">
      <div className="container mx-auto flex flex-col md:flex-row justify-center md:justify-between items-center space-y-6 md:space-y-0">
        <Link
          to="/"
          className="text-2xl md:text-3xl font-newsreader-light hover:text-gray-500"
        >
          {translate("footer.site_name")}
        </Link>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8">
          <Link to="/privacy-policy" className="hover:text-gray-500">
            {translate("footer.privacy_policy")}
          </Link>
          <Link to="/contact-us" className="hover:text-gray-500">
            {translate("footer.contact_us")}
          </Link>
          <Link to="/feedback" className="hover:text-gray-500">
            {translate("footer.feedback")}
          </Link>
          <Link to="/faq" className="hover:text-gray-500">
            {translate("footer.faq")}
          </Link>
          <LanguageSwitcher />
        </div>
      </div>
      <div className="mt-8 text-center text-sm italic">
        {translate("footer.copyright")}
      </div>
    </footer>
  );
};
